<template>
    <layout-admin-dashboard-view>
        <template v-slot:title>
           {{translateTitleCase('admin.language.title')}}
        </template>
        <template v-slot:toolbarStart>
            <router-link  :to="{name:'admin-dashboard'}"
                          custom
                          v-slot="{ navigate, href, route }">
                <form-button @click="navigate"
                             icon="arrow-left"
                             type="default"
                             :uk-tooltip="translateTitleCase('admin.general.back')"
                             :showSlot="false">

                </form-button>
            </router-link>
        </template>

        <template v-slot:toolbarEnd> </template>

        <template v-slot:default>
            <block>
                <h3>{{safeTranslate('admin.language.overrideSearchTitle')}}</h3>
                <div class="override-search">
                    <base-form
                            ref="overrideSearchForm"
                            action="/language/override"
                            method="get"
                            formPath="form/language-adminSearch"
                            :expectedFields="2"
                            v-model="searchData"
                            :config="{formHandling}"
                            @change="doLiveSearch"
                            @form:submitSuccess="searchSuccessHandler"

                    ></base-form>
                    <div  class="uk-margin-top">
                        <h4 class="uk-text-center uk-text-semibold" v-if=" ! stringsSearched">{{safeTranslate('admin.language.overrideSearchCta')}}</h4>
                        <h4 class="uk-text-center uk-text-semibold" v-if="stringsSearched &&  strings.length < 1">{{safeTranslate('admin.language.overrideSearchNoResults')}}</h4>

                        <div class="results" v-if="strings.length > 0">
                            <div class="uk-overflow-auto">
                                <table class="uk-table uk-table-striped">
                                    <thead>
                                    <tr>
                                        <th>{{safeTranslate('admin.language.overrideSearchResultHeaderPath')}}</th>
                                        <th>{{safeTranslate('admin.language.overrideSearchResultHeaderText')}}</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="arrString of strings" :key="arrString['key']">
                                        <td class="uk-overflow-auto">{{arrString['key']}}</td>
                                        <td>{{arrString['final']}}</td>
                                        <td><form-button icon="pencil" @click="editString(arrString)">{{safeTranslate('admin.language.overrideResultEdit')}}</form-button></td>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>


            </block>

            <teleport to="body">
                <modal :verticalCenter="true"
                       class="uk-margin-auto-vertical"
                        title="Edit String"
                       :show="editingString">
                    <template #default>
                       <div class="uk-card uk-card-muted uk-margin-bottom uk-card-body uk-padding-small">
                           <div class="string-summary uk-flex uk-flex-stretch">
                               <div class="icon uk-flex  uk-flex-center uk-margin-right">
                                   <span uk-icon="icon: world; ratio: 3"></span>
                               </div>
                               <div class="details">
                                   <h4 class="uk-margin-remove">{{safeTranslate('admin.language.overrideModalTitleSummary')}}</h4>
                                   <strong class="">{{safeTranslate('admin.language.overrideModalTitleKey')}}: </strong><span>{{currentStringEdit.path}}</span>
                               </div>
                           </div>
                           <hr class="uk-hr">
                           <div class="detail uk-flex">
                               <strong class="uk-display-inline-block" style="min-width: 130px;">{{safeTranslate('admin.language.overrideModalOriginalTextLabel')}}: </strong>
                               <span class="uk-display-inline-block">{{currentStringEdit.original}}</span>
                           </div>
                           <div class="detail uk-flex">
                               <strong class="uk-display-inline-block" style="min-width: 130px;">{{safeTranslate('admin.language.overrideModalCurrentTextLabel')}}: </strong>
                               <span class="uk-display-inline-block">{{currentStringEdit.text}}</span>
                           </div>
                           <div class="detail uk-flex">
                               <strong class="uk-display-inline-block" style="min-width: 130px;">{{safeTranslate('admin.language.isActiveOverride')}}: </strong>
                               <span class="uk-display-inline-block uk-text-success" v-if="currentStringEdit.isDbOverride">{{translateUcFirst('core.yes', {}, true)}}</span>
                               <span class="uk-display-inline-block uk-text-danger" v-else>{{translateUcFirst('core.no', {}, true)}}</span>
                           </div>
                       </div>
                        <form-input type="text" label="Override text" v-model="desiredOverrideText"></form-input>
                    </template>
                    <template #footer>
                        <form-button type="danger"
                                     icon="close"
                                     v-if="currentStringEdit.isDbOverride"
                                     class="uk-margin-right-small"
                                     @click="deleteOverride(currentStringEdit)">{{safeTranslate('admin.language.deleteCurrentOverrideButton')}}</form-button>
                        <form-button type="muted"
                                     icon="close"
                                     class="uk-margin-right-small"
                                     @click="editingString = false">{{safeTranslate('admin.language.overrideModalButtonCancel')}}</form-button>
                        <form-button type="primary"
                                     icon="check"
                                     @click="submitEditString()">{{safeTranslate('admin.language.overrideModalButtonSave')}}</form-button>
                    </template>

                </modal>
            </teleport>
        </template>
    </layout-admin-dashboard-view>
</template>

<script>
    import asyncOperations  from '@/client/extensions/composition/asyncOperations.js';
    import { useMeta } from 'vue-meta'
    import {useI18n} from "vue-i18n/index";

    export default {
        setup (props) {
            let {asyncOps, asyncOpsReady} = asyncOperations(props);
            let {t} = useI18n();
            let {meta} = useMeta( {
                'title': t('admin.language.title'),
            });

            return {asyncOps, asyncOpsReady, asyncCall: asyncOps.asyncCall, meta}
        },
        data: function () {
            return {
                liveSearchTimeout : null,
                searchData : { // TODO: this might break in strict store mode
                    slug: this.$store.getters['locale/slug'], // use store here
                    text: '',
                },
                stringsSearched : false,
                strings: [],
                currentStringEdit: {},
                editingString: false,
                desiredOverrideText: ''
            };
        },
        methods: {
            searchSuccessHandler(eventData) {
                this.stringsSearched = true;
                this.strings = eventData.result.data;
            },
            editString(arrString) {
                this.currentStringEdit = {
                    path : arrString['key'],
                    text : arrString['final'],
                    original : arrString['original'],
                    isDbOverride : arrString['isDbOverride'] || false,
                };
                this.editingString = true;
                this.desiredOverrideText = this.currentStringEdit.text;

            },
            async deleteOverride(input) {
                let data = {path: input.path, slug: this.searchData.slug }

                let result = await this.asyncCall('language/override', data, {method: 'delete'});
                if (result.isError) {
                    this.$s.ui.notification(this.translate('core.errorOccurredGeneric'), 'danger');
                    return;
                }

                this.editingString = false;
                this.$refs.overrideSearchForm.submit();
                this.i18nReset();


            },
            async submitEditString() {
                let result = await this.asyncCall('language/override', {
                    path: this.currentStringEdit.path,
                    text: this.desiredOverrideText,
                    language: this.searchData.slug
                }, {'method': 'post'});

                if ( ! result.isError) {
                    this.editingString = false;
                    this.desiredOverrideText = '';
                    this.$s.ui.notification(this.safeTranslate('admin.language.overrideSuccess', 'success'));
                    this.i18nReset();
                    this.$refs.overrideSearchForm.submit();
                } else {
                    this.$s.ui.notification(this.safeTranslate('admin.language.overrideError', 'error'));
                }


            },
            doLazyLiveSearch () {
                clearTimeout(this.liveSearchTimeout);
                this.liveSearchTimeout = setTimeout( () => {

                    this.$refs.overrideSearchForm.submit()
                }, 100);
            }
        },
        computed: {

        },
        watch: {
            searchData : {
                handler (newVal) {
                    if (newVal.slug && typeof newVal.text === 'string' && newVal.text.length > 2) {
                        this.doLazyLiveSearch();
                    }
                },
                deep: true,
                immediate: false,
            }
        },

    }
</script>

<style scoped lang="scss">




</style>
